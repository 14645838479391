// GLOBAL IMPORT
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import {
  DataStateChangeEventArgs,
  DataResult,
} from "@syncfusion/ej2-angular-grids";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Http, ResponseContentType } from "@angular/http";

import { Globals } from "../common/globals";
import { API } from "./apiurl";
import { ExtraParams } from "../_models/index";
import { ConfigService } from "./config.service";

export interface ImgurUploadResponse {
  link: string;
  deleteHash: string;
}

Injectable();
export class DashBoardService extends Subject<DataStateChangeEventArgs> {
  public loadCouncilSubject = new Subject<any>();

  constructor(
    @Inject(Http) private http: Http,
    @Inject(Router) private router: Router,
    @Inject(Globals) private globals: Globals,
    @Inject(ConfigService) private configService: ConfigService
  ) {
    super();
  }

  GetDataOverView = (body: any) => {
    const url_request = this.globals.apiURL + API.dashboard + "GetDataOverView";
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);
    return this.http.post(url_request, body, options).pipe(
      map((response: any) => {
        response = JSON.parse(response._body);
        return response;
      })
    );
  };

  GetDataCount = (body: any) => {
    const url_request = this.globals.apiURL + API.dashboard + "GetDataCount";
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);
    return this.http.post(url_request, body, options).pipe(
      map((response: any) => {
        response = JSON.parse(response._body);
        return response;
      })
    );
  };

  GetPersonAtOrganization = (body: any) => {
    const url_request = this.globals.apiURL + API.dashboard + "GetPersonAtOrganization";
    const options = this.globals.getCommonOptionsWithAuth(url_request, body);
    return this.http.post(url_request, body, options).pipe(
      map((response: any) => {
        response = JSON.parse(response._body);
        return response;
      })
    );
  };

  //Service get list
}
